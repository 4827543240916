import { ajax } from '@/js/extension'

const BASE_URL = '/gateway/platform-lmsapi/runtime'

const MaintenanceApi = {
  getPortfolioDetailByPortfolioId (portfolioId, success, failure) {
    return ajax.get(BASE_URL + '/portfolio-parameter/get', portfolioId, success, failure)
  },
  getProgramByPortfolioId (portfolioId, success, failure) {
    return ajax.get(BASE_URL + '/program/list', { portfolioId: portfolioId }, success, failure)
  }
}

export { MaintenanceApi }
