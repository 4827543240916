<template>
  <div class="width-full height-full">
    <v-parallax class="height-full width-full m-0">
      <v-row
        align="center"
        justify="center"
        style="background-color: rgba(0, 0, 0, 0.2); backdrop-filter: blur(20px); padding: 0"
      >
        <v-col
          class="text-center"
          cols="12"
        >
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="5"
            :value="value"
            color="teal"
          >
            <v-btn
              outlined
              fab
              x-large
              color="teal"
              :disabled="isLoading"
              class="size-100x100">
              <h2>
                Loan
              </h2>
            </v-btn>
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
import { OriginationApi } from '@/api/application'
import { MaintenanceApi } from '@/api/maintenance'
import { Tool, DataType } from '@/js/core'

const tool = new Tool()
export default {
  data () {
    return {
      route: '/notification/-100',
      isLoading: true,
      value: 0,
      size: 0,
      loanAmount: '',
      loanDetail: {},
      loanId: ''
    }
  },
  methods: {
    progress () {
      const _this = this
      const interval = setInterval(function () {
        if (_this.value < _this.size) {
          _this.value++
        } else if (_this.value === 100) {
          _this.isLoading = false
          clearInterval(interval)
        }
      }, 50)
    },
    error () {
      console.log('Error')
    }
  },
  async created () {
    const query = window.location.href
    OriginationApi.getIp(function (result) {
      localStorage.setItem('IP', result.ip)
    })
    this.size = 10
    this.progress()
    const _this = this
    const encode = query.substring(query.lastIndexOf('/') + 1)
    await OriginationApi.getConsentData(
      { encode: encode },
      (res) => {
        if (tool.isNotEmpty(res)) {
          const consentData = JSON.parse(res)
          _this.loanId = consentData.loanId
          localStorage.setItem(DataType.COOKIE_KEY.LOAN_ID, _this.loanId)
          localStorage.setItem(DataType.COOKIE_KEY.WSID, tool.md5(_this.loanId))
          tool.setItem('LI', consentData.loanId)
          tool.setItem('AT', consentData.token)
          _this.$store.commit('setRedirectUrl', consentData?.redirectUrl)
          _this.$store.commit('setFromLA', true)
          if (tool.isNotEmpty(consentData?.templates)) {
            this.templates = consentData.templates
          } else {
            this.templates = []
          }
        }
      },
      () => {
        _this.error()
      }
    )
    await OriginationApi.getPersonalDataByLoanId({
      loanId: _this.loanId
    }, function (result) {
      localStorage.setItem(DataType.COOKIE_KEY.LOAN_ID, _this.loanId)
      localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(result))
      localStorage.setItem(DataType.COOKIE_KEY.WSID, tool.md5(_this.loanId))
      _this.size = 20
    }, function (res) {
      _this.error()
    })
    await OriginationApi.getLoanById({
      loanId: _this.loanId,
      feature: 'STATUS,PAYMENT_OPTION'
    }, function (result) {
      localStorage.setItem(DataType.COOKIE_KEY.CUSTOMER_ID, result.customerId)
      localStorage.setItem(DataType.COOKIE_KEY.LOAN_TYPE, result.loanType)
      localStorage.setItem(DataType.COOKIE_KEY.CATEGORY, result.category)
      if (result.flags === 1 && result.priority > 900) {
        localStorage.setItem('PRIORITY', result.priority)
      }
      _this.loanDetail = result
    }, function (res) {
      _this.$router.push(_this.route)
    }, function () {
      _this.error()
    })
    _this.size = 40

    await MaintenanceApi.getPortfolioDetailByPortfolioId({ portfolioId: _this.loanDetail.portfolioId }, function (result) {
      localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO, JSON.stringify(result))
      _this.$store.commit('setPortfolioJson', result)
    })
    localStorage.setItem(DataType.COOKIE_KEY.PORTFOLIO_ID, _this.loanDetail.portfolioId)
    _this.size = 100
    if (tool.isEmpty(this.templates)) {
      const addressSignatrue = tool.isPC() ? DataType.PAGES.SIGNATURE.addressPC : DataType.PAGES.SIGNATURE.address
      _this.$router.push(addressSignatrue)
    } else {
      this.$router.push(DataType.PAGES.WELCOME.addressPC)
    }
  }
}
</script>

<style lang="css">
  .v-parallax__image {
    transform: translate(-50%, 0) !important
  }

  .v-parallax__content {
    padding: 0px !important;
  }
</style>
